import React from "react"

import Image from "../../image"
import Button from "../../Button"
import Listing from "../../Listing"

const ImageTextPriceListing = ({
  Filename,
  Position,
  title,
  alt,
  download,
  downloaddataGoal,
  downloadhref,
  downloadclassname,
  downloadtext,
  list,
  borderRadius,
}) => {
  return (
    <section data-theme="white">
      <div className="container container--md margin-top--lg margin-bottom--lg margin---none">
        <div
          className={`grid grid--gap-lg flex--center-x flex--center-y ${Position}`}
        >
          <div className="col col--12 col--sm-7">
            <Image
              style={borderRadius && { borderRadius: "16px!important" }}
              filename={Filename}
              alt={alt}
              classname=""
            />
          </div>
          <div className="col col--12 col--sm-5">
            <div className="text--component text--left list--checklist">
              <h2>{title}</h2>
              {list && <Listing style={{ fontWeight: "400" }} listing={list} />}
              <p className="strikethrough opacity--6">£60/month</p>
              <p style={{ marginTop: "0px" }} className="text--lg">
                £40/month
              </p>
              {download && (
                <Button
                  dataGoal={downloaddataGoal}
                  href={downloadhref}
                  classname={downloadclassname}
                  text={downloadtext}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ImageTextPriceListing
