import React from "react"
import Image from "../../image"

const AppFeatures = ({ alt, imgSize, title, grids, storybook }) => {
  return (
    <section data-theme="white">
      <div className="container container--lg margin-top--lg margin-bottom--lg margin---none">
        <div className="grid grid--gap-xl flex--center-x text--center">
          <h2>{title}</h2>
          {grids &&
            grids.map(grids => (
              <div
                key={grids.text}
                className={`col col--12 col--sm-4 ${imgSize}`}
              >
                {storybook ? (
                  <img src="https://via.placeholder.com/150x150" alt="sb pic" />
                ) : (
                  <Image
                    classname="AppImgFade"
                    filename={grids.Filename}
                    alt={alt}
                  />
                )}
                <div className="text--component text--center list--checklist">
                  <h3
                    className="text--md margin-top--sm margin-left--md margin-right--md"
                    style={{ maxWith: "70px" }}
                  >
                    {grids.title}
                  </h3>

                  <p>{grids.text}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}
export default AppFeatures
