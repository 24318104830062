import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ButtonFontawesome = ({ icon, text }) => (
  <div className="flex" style={{ alignItems: "center" }}>
    <FontAwesomeIcon className="margin-right--xxs" icon={icon} />
    <p style={{ alignItems: "center" }}>{text}</p>
  </div>
)

export default ButtonFontawesome
