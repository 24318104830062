import * as React from 'react';
import { arrayOf, shape, string, objectOf } from 'prop-types';

const Listing = ({ listing, style }) => {
  if (listing.length === 0) {
    return null;
  }

  return (
    <ul className="checklist text--sm margin-top--sm">
      {listing
        && listing.map((list) => (
          <li key={list.li} style={style}>
            {list.li}
          </li>
        ))}
    </ul>
  );
};

Listing.defaultProps = {
  listing: [],
  style: {},
};

Listing.propTypes = {
  style: objectOf(string),
  listing: arrayOf(shape({
    li: string.isRequired,
  })),
};

export default Listing;
