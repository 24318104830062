import React from "react"
import dataOutcome from "../../../data/dataOutcome.json"

const Outcome = ({ title, text, journals, lang, dataTheme }) => {
  const language = typeof lang === "undefined" ? "se_sv" : lang
  const content = dataOutcome[language]

  return (
    <section data-theme={dataTheme} className="Outcome">
      <div className="container container--md margin-top--lg margin-bottom--md">
        <div className="text--component margin-bottom--md text--center">
          <h2>{title}</h2>
          <p>{text}</p>
        </div>
        <div className="swiper-container swiper--cards cards--float">
          <div className="swiper-wrapper">
            {content &&
              content.map(cards => (
                <div key={cards.title} className="swiper-slide">
                  <div className="flex flex--column flex--center extraPad">
                    <p className="text--title text--bold color--electric-blue text--xxxl">
                      {cards.data}
                    </p>
                    <p className="margin-bottom--xs text--title text--md">
                      {cards.title}{" "}
                      <sup className="text--xs">{cards.journalTarget}</sup>
                    </p>
                  </div>
                </div>
              ))}
            <div className="swiper-pagination  margin-top--md"></div>
          </div>
        </div>
        <div className="col col--12 col--lg-12">
          <div className="flex flex--column">
            {journals &&
              journals.map(journals => (
                <p key={journals.text} className={journals.classname}>
                  <a href={journals.url} className="color--dark-grey-blue">
                    {journals.text}
                  </a>
                </p>
              ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Outcome
