import React from "react"
import Button from "../../Button"

const faq = ({
  title,
  faqs,
  dataTheme,
  borderTop,
  subTitleText,
  calldataGoal,
  callhref,
  callclassname,
  calltext,
  idCta,
  CtaCall,
}) => {
  return (
    <section className="faq" data-theme={dataTheme} id="faq">
      <div className="container container--md" style={{ borderTop }}>
        <div className="container container--sm margin-top--lg margin-bottom--lg">
          <div className="text--center margin-bottom--xs">
            <h2>{title}</h2>
            {subTitleText && (
              <p className="margin-top--sm margin-bottom--sm">
                Still wondering about something? Look below to find the answers
                to our most frequently asked questions.
              </p>
            )}
          </div>
          <ul
            data-animation="on"
            data-multi-items="off"
            className="accordion accordion"
          >
            {faqs &&
              faqs.map(faq => (
                <li
                  key={faq.question}
                  className="accordion__item accordion__item"
                >
                  <button
                    data-goal="FAQ Clicked"
                    className="accordion__header"
                    type="button"
                  >
                    <span className="accordion__header-title">
                      {faq.question}
                    </span>
                    <em aria-hidden="true" className="accordion__header-icon">
                      <i></i>
                    </em>
                  </button>
                  <div className="accordion__panel accordion__panel">
                    <div className="accordion__panel-content">
                      <div className="text--component margin-bottom--md text--sm">
                        <p>{faq.answer}</p>
                        <p>{faq.subText}</p>
                        <div className="margin-top--xs">
                          {faq.list &&
                            faq.list.map(list => {
                              return (
                                <p
                                  style={{
                                    marginTop: "0px",
                                    paddingLeft: "12px",
                                  }}
                                  key={list.dataList}
                                >
                                  {list.dataList}
                                </p>
                              )
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
          <div className="flex--center text--center">
            {calltext && (
              <Button
                idTrack={idCta}
                dataGoal={calldataGoal}
                href={callhref}
                classname={callclassname}
                text={calltext}
              />
            )}
            {CtaCall && (
              <div className="flex--center text--center margin-top--md">
                <p className="margin-bottom--xs">
                  You can always reach out to us if you have any more questions
                </p>
                <a className="btn btn--outline btn--md" href="tel:9295476370">
                  929-547-6370
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default faq
