import React from "react"
import Image from "../../image"
// Use icons from our library
import "../../../utils/fontawesome"
import ButtonFontawesome from "../../ButtonFontawesome"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';

const licensedPtSlider = ({
  title,
  text,
  slides,
  subTitle,
  listing,
  downloadhref,
  downloadclassname,
  downloaddataGoal,
  downloadtext,
  ButtonFont,
  buttonTitle,
}) => {
  return (
    <section data-theme="white">
      <div className="container container--md margin-top--lg margin-bottom--lg">
        <div className="text--component margin-bottom--xs text--center">
          <h2>{title}</h2>
          <p>{text}</p>
          {ButtonFont && (
            <div className="flex margin-top--xs fontawesomeIcons">
              {ButtonFont &&
                ButtonFont.map(font => (
                  <ButtonFontawesome
                    key={font.text}
                    icon={font.icon}
                    text={font.text}
                  />
                ))}
            </div>
          )}
        </div>

        <div className="grid grid-gap--lg flex--center flex--space-between-lg">
          <div className="col col--8 col--sm-5">
            <div className="image_stack">
              <div className="swiper-container swiper-container--fade">
                <div className="swiper-wrapper">
                  {slides &&
                    slides.map(slide => (
                      <div
                        key={slide.alt}
                        className="swiper-slide"
                        style={{ width: "100%!important" }}
                      >
                        <Image filename={slide.Filename} alt={slide.alt} />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col col--12 col--sm-6">
            <div className="text--component">
              <h3>{subTitle}</h3>
              <div className="margin-bottom--sm">
                {listing &&
                  listing.map(list => (
                    <div
                      key={list.li}
                      className="flex margin-top--sm"
                      style={{ alignItems: "center" }}
                    >
                      <FontAwesomeIcon icon={faCheckCircle} />
                      <p className="text--md">{list.li}</p>
                    </div>
                  ))}
              </div>

              {buttonTitle && <h3 className="margin-top--md">{buttonTitle}</h3>}
              <a
                href={downloadhref}
                className={downloadclassname}
                data-goal={downloaddataGoal}
              >
                {downloadtext}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default licensedPtSlider
