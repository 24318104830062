import React from "react"

import Image from "../../image"

const GetStarted = ({
  title,
  text,
  cards,
  ctaButtonText,
  ctaDataGoal,
  ctaHref,
  widthIcons,
  renderSubTitle,
  renderDownloadButton,
}) => {
  return (
    <section data-theme="white">
      <div className="container container--lg margin-top--lg margin-bottom--lg margin---none">
        <div className="text--component text--center margin-bottom--md">
          <h2 className="margin-bottom--xs">{title}</h2>
          {text && (
            <p style={{ maxWidth: "800px", margin: "0 auto" }}>{text}</p>
          )}

          {renderSubTitle && (
            <h3 className="text--center margin-bottom--md margin-top--lg">
              {renderSubTitle}
            </h3>
          )}
        </div>
        <div className="grid grid--gap-md text--center">
          {cards &&
            cards.map(card => (
              <div key={card.title} className="col col--12 col--sm-3">
                <Image
                  alt={card.alt}
                  filename={card.Filename}
                  classname={widthIcons}
                />

                <div className="text--component margin-top--sm">
                  <h3 className="text--md">{card.title}</h3>
                  <p>{card.text}</p>
                </div>
              </div>
            ))}
          <div>
            {/* <a
              data-goal={ctaDataGoal}
              href={ctaHref}
              className="btn btn--primary btn--md btn--lgMob"
            >
              {ctaButtonText}
            </a> */}
            {renderDownloadButton && (
              <a
                data-goal={ctaDataGoal}
                href={ctaHref}
                className="btn btn--primary btn--md btn--lgMob margin-top--md"
              >
                {ctaButtonText}
              </a>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
export default GetStarted
