import React from 'react';

import Layout from '@components/layout';
import Segment from '@components/segment';
import Seo from '@components/seo';

import HeroCTA from '@components/sections/heroCTA/heroCTA';
import AppFeatures from '@components/sections/appFeatures/appFeatures';
import SocialProofCards from '@components/sections/socialProofCards/socialProofCards';
import LicensedPtSlider from '@components/sections/licensedPtSlider/licensedPtSlider';
import Outcome from '@components/sections/outcome/outcome';
import GetStarted from '@components/sections/getStarted/getStarted';
import Faq from '@components/sections/faq/faq';
import ImageTextPriceListing from '@components/sections/imageTextPriceListing/imagetextPriceListing';

const IndexPage = () => (
  <Layout
    lang="uk"
    topHeader
    headerWitdh="header"
    headerStyle="flex--space-between"
    text="Limited offer! Get started now for only £40 (normally £60)"
    showFooter
  >
    <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_US} />
    <Seo title="UK Trial" language="en" />
    <HeroCTA
      dataTheme="ice-blue"
      idCta="herobutton"
      imagedataNoCerts
      dataSection="hero_CTA"
      alt="blue shield of california"
      title="Treat chronic joint pain from home"
      titleClassName=""
      text="Joint Academy is a clinically proven digital treatment programme with personalised activities to reduce joint pain. Get unlimited remote support from a registered physiotherapist. Sign up today for a 7-day free trial to get started."
      textClassName=""
      calldataGoal="Store Viewed"
      callhref="https://app.adjust.com/a97bekp"
      callclassname="margin-top--md btn btn--primary btn--fullwidth btn--lg margin-bottom--xs"
      calltext="Start 7-day free trial"
    />
    <ImageTextPriceListing
      borderRadius
      Filename="app-today-us-mock.jpg"
      alt="blue california"
      Position=""
      title="Sign up today"
      download
      downloaddataGoal="Store Viewed"
      downloadhref="https://app.adjust.com/a97bekp"
      downloadclassname="btn btn--primary btn--md"
      downloadtext="Start 7-day free trial"
      list={[
        {
          li: 'Easy physical activities to reduce pain',
        },
        {
          li: 'Support from a registered physiotherapist',
        },
        {
          li: 'Interactive education about osteoarthritis',
        },
        {
          li: 'Daily reminders and progress tracking',
        },
      ]}
    />
    <GetStarted
      title="How it works"
      cards={[
        {
          alt: 'App',
          Filename: 'illustration_circle_app@3x.png',
          title: '1. Download the app',
          text:
              'Download the Joint Academy app to your smartphone from App Store or Google Play',
        },
        {
          alt: 'Pt',
          Filename: 'illustration_circle_pt@3x.png',
          title: '2. Meet physiotherapist',
          text:
              'Connect with a personal registered physiotherapist that will help you get started',
        },
        {
          alt: 'Treatment',
          Filename: 'illustration_circle_schedule@2x.png',
          title: '3. Begin treatment',
          text:
              'Every day you get two quick physical activities to complete that help reduce pain',
        },
      ]}
      widthIcons="widthIcons"
      renderDownloadButton
      ctaButtonText="Get started"
      ctaDataGoal="Store Viewed"
      ctaHref="https://app.adjust.com/a97bekp"
    />
    <AppFeatures
      title="All-in-one solution to help you reduce joint pain"
      grids={[
        {
          Filename: 'app_feature_fade_today_us.jpg',
          title: 'Physical activities tailored to you',
          text:
              'Get daily exercises that automatically adjust to you as your pain improves. By strengthening muscles around the joint you relieve pain.',
        },
        {
          Filename: 'app_feature_fade_chat_us.jpg',
          title: 'A personal physiotherapist',
          text:
              'Connect with a registered physiotherapist that tailors the treatment to your needs and support you through messaging, phone and video calls.',
        },
        {
          Filename: 'app_feature_fade_pain_us.jpg',
          title: 'Goals and progress tracking',
          text:
              'Set goals and track your progress as your pain improves. Daily reminders and follow-ups help you stay on track to reach your goals.',
        },
      ]}
    />
    <SocialProofCards
      lang="us_en"
      title="Thousands of happy patients"
      cards={[
        {
          name: 'Robert',
          Filename: 'josef.png',
          alt: 'robert',
          title: 'Joint Academy patient',
          text: 'My pain disappeared after a few weeks with Joint Academy.',
        },
        {
          name: 'Anne',
          Filename: 'karlsson.png',
          alt: 'anne',
          title: 'Joint Academy patient',
          text:
              'I don’t need any painkillers or surgery and I can still play tennis.',
        },
        {
          name: 'Lotta',
          Filename: 'ling.png',
          alt: 'lotta',
          title: 'Joint Academy patient',
          text:
              'It took me five minutes a day to make a difference and feel good.',
        },
      ]}
      ctaButtonText="Get started today"
      dataGoal="Store Viewed"
      href="https://app.adjust.com/a97bekp"
    />
    <LicensedPtSlider
      title="A personal registered physiotherapist"
      text="The physiotherapist we connect you with is ready to support you every day of the week. They also keep an eye on your progress and help you reach your goals."
      ButtonFont={[
        {
          icon: 'video',
          text: 'Video Call',
        },
        {
          icon: 'comments',
          text: 'Messaging',
        },
        {
          icon: 'phone-alt',
          text: 'Phone',
        },
      ]}
      slides={[
        {
          Filename: 'pt-1.jpg',
          alt: 'pt-1',
        },
        {
          Filename: 'pt-2.jpg',
          alt: 'pt-2',
        },
        {
          Filename: 'pt-3.jpg',
          alt: 'pt-3',
        },
        {
          Filename: 'pt-4.jpg',
          alt: 'pt-4',
        },
        {
          Filename: 'pt-5.jpg',
          alt: 'pt-5',
        },
      ]}
      subTitle="Ready to help you reach your goals"
      listing={[
        {
          li: 'Experts on chronic joint pain',
        },
        {
          li: 'Ready to answer questions via chat',
        },
        {
          li: 'There for you throughout the treatment',
        },
      ]}
      downloaddataGoal="Store Viewed"
      downloadhref="https://app.adjust.com/a97bekp"
      downloadclassname="btn btn--fullwidth btn--md btn--primary btn--lgMob"
      downloadtext="Get started today"
    />
    <Outcome
      dataTheme="ice-blue"
      lang="us_en"
      title="No pills. No surgery. Just healthier joints."
      text="Our peer-reviewed studies and ongoing research show that Joint Academy patients reduce their pain, change their minds about surgery, and quit analgesics medication."
      journals={[
        {
          text:
              '1) H. Nero, S. Lohmander, L.E. Dahlberg. Improved patient outcomes by a first-line osteoarthritis self-management programme delivered digitally. Volume 28, SUPPLEMENT 1, S164-S165, April 01, 2020',
          classname: 'text--xxs opacity--6 margin-top--lg text--center',
          url:
              'https://www.oarsijournal.com/article/S1063-4584(20)30334-4/fulltext',
        },
        {
          text:
              "2) H. Nero, S. Lohmander, L.E. Dahlberg, Dell'Isola A, A. Cronström, Changes in Patients’ Wish to Undergo Joint Surgery While Participating in a 12-Month Digital Management Programme for Hip and Knee Osteoarthritis (publication pending)",
          classname: 'text--xxs opacity--6 margin-top--xxxs text--center',
          url:
              'https://www.oarsijournal.com/article/S1063-4584(20)30117-5/fulltext',
        },
      ]}
    />
    <Faq
      dataTheme="white"
      borderTop=""
      title="FAQ"
      faqs={[
        {
          question: 'How long does the Joint Academy programme last?',
          answer:
              'Programme length varies depending on your unique health needs and goals, but success with your personal lifestyle goals will determine the programme duration. In addition, regular communication with your physiotherapist will help guide this timeline.',
        },
        {
          question: 'Who is eligible for Joint Academy?',
          answer:
              "Joint Academy is a personalised experience designed to support each individual's specific needs related to osteoarthritis (OA) of the knee and hip. This includes people who have recently been diagnosed with OA, have lived with the disease long term, and those attempting to avoid joint replacement surgery.",
        },
        {
          question: 'How can Joint Academy exercises help me?',
          answer:
              "Guided exercises have been proven by research as an effective treatment method for hip and knee osteoarthritis. Joint Academy's exercise program is designed to help strengthen the muscles necessary to stabilise and protect your painful hip or knee joint. In addition, as your muscles strengthen, the cartilage (material covering the surface of the joints, which acts as a shock absorber and allows for smooth sliding/gliding of the joint) is stimulated to repair itself. Also worth considering, is not all types of strength and activity are the same when addressing osteoarthritis. Highly active individuals may inadvertently miss strengthening the specific muscles needed to achieve relief from their joint pain.",
          subText:
              'Please seek immediate medical attention if you have the below symptoms:',
          list: [
            {
              dataList:
                  '1. Inability to weight-bear with possible pain, swelling, redness and restricted ',
            },
            {
              dataList:
                  '2. Hot red swollen painful joint, with possible fever and night sweats',
            },
          ],
        },
        {
          question: 'What will I do on the first day of the programme?',
          answer:
              'The programme officially starts the day after you complete your registration and you have been assigned to a physiotherapist.',
        },
        {
          question:
              'Does Joint Academy treat other joints besides hip and knee?',
          answer:
              'Patients can have co-morbidities and other painful/dysfunctional joints, such as low back or neck and shoulder pain. At this time, Joint Academy only treats individuals with hip and knee osteoarthritis (OA). Therefore, patients are only appropriate candidates for the programme if they have hip or knee OA and should only be signing up if they are seeking treatment for this diagnosis. Joint Academy will likely expand to other body regions in the future but at this time this is our current focus.',
        },
        {
          question: 'What privacy measures are in place to protect me?',
          answer:
              'As a healthcare company, Joint Academy takes security and patient privacy very seriously. We operate in accordance with all applicable privacy and data protection laws. Our company is committed to providing best-in-class physical, technical, and administrative controls to protect your personal information. (See Privacy Policy: https://www.jointacademy.com/us/en/policy/)',
        },
      ]}
    />
  </Layout>
);

export default IndexPage;
