import React from "react"

import Image from "../../image"
import dataSocial from "../../../data/dataSocial.json"
import ButtonCalendarPopup from "../../buttonCalendarPopup"

const SocialProofCards = ({
  title,
  cards,
  ctaTitle,
  ctaText,
  ctaButtonText,
  dataGoal,
  href,
  lang,
  rederCalendly,
  classnameCalendly,
  titleCalendlyButton,
}) => {
  const language = typeof lang === "undefined" ? "se_sv" : lang
  const content = dataSocial[language]
  return (
    <section
      id="socialproof"
      data-name="socialproof"
      className="ice-blue-light"
    >
      <div className="container container--lg margin-top--lg margin-bottom--lg">
        <div className="stats_bar">
          <div className="grid flex flex--center-y margin-bottom--md">
            <div className="col col--12 col--lg-7">
              <div className="text--center margin-bottom--md">
                <h2>{title}</h2>
              </div>
              <div className="flex flex--space-between flex--center-y">
                {content &&
                  content.map(cards => (
                    <div
                      key={cards.title}
                      className="text--center"
                      style={{ flexShrink: "0" }}
                    >
                      <p
                        className="text--xl text--title text--700"
                        style={{ lineHeight: "1em" }}
                      >
                        {cards.data}
                      </p>
                      <p className="text--sm text--title opacity--8 margin-bottom--xxs">
                        {cards.title}
                      </p>
                      <Image
                        classname="social-icons"
                        filename={cards.icon}
                        alt={cards.icon}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="swiper-container swiper--cards cards--float">
            <div className="swiper-wrapper">
              {cards &&
                cards.map(card => (
                  <div key={card.name} className="swiper-slide">
                    <div className="flex flex--column col col--12 col--lg-12 ">
                      <div className="flex flex--column-y flex flex--start flex--gap-sm ">
                        <div style={{ width: "72px" }}>
                          <Image filename={card.Filename} alt={card.alt} />
                        </div>
                        <div className="flex flex--column flex--center-x margin-left--xxs">
                          <p className="text--title text--bold color--dark-grey-blue text--xs text--left">
                            {card.name}
                          </p>
                          <p className="text--default text--xs text--left">
                            {card.title}
                          </p>
                        </div>
                      </div>
                      <div className="text--left margin-top--sm">
                        <p className="text--default text-left">“{card.text}”</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="swiper-pagination margin-top--md"></div>
          </div>
          <div className="flex flex--column text--center margin-bottom--sm margin-top--md">
            <p className="text--bold">{ctaTitle}</p>
            <p>{ctaText}</p>
          </div>
          <div style={{ maxWidth: "340px", margin: "0 auto" }}>
            <div className="flex flex--center">
              {ctaButtonText && (
                // Button link standar props
                <a
                  data-goal={dataGoal}
                  href={href}
                  className="btn btn--primary btn--fullwidth btn--lg margin-bottom--xs"
                >
                  {ctaButtonText}
                </a>
              )}

              {
                rederCalendly && (
                  <ButtonCalendarPopup
                    classname={classnameCalendly}
                    titleCalendlyButton={titleCalendlyButton}
                  />
                ) // button component to open calendar use rederCalendly
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SocialProofCards
